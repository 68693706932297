import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const MeasurementAttributes = () => {
  const data = useStaticQuery(graphql`
    query {
      gcms {
        contentBlocks(
          where: { identifier: "demand-measurement-attribute" }
          first: 4
        ) {
          title
          id
          icon {
            url
          }
          mainContent {
            html
          }
        }
      }
    }
  `)

  const cols = data.gcms.contentBlocks

  return (
    <div className="max-w-s mx-auto m:max-w-m l:max-w-l flex flex-col items-center">
      <div className="text-center grid grid-cols-1 m:grid-cols-2 l:grid-cols-4  justify-start gap-x-2 gap-y gap-y-8 mt-16 m:mt-8">
        {cols.map((c, i) => (
          <div
            className="w-full flex flex-col items-center justify-start text-white font-body text-center text-sm leading-6 px-2"
            key={i}
          >
            {c.icon && <img src={c.icon.url} alt={c.title} />}
            <h3>{c.title}</h3>
            <div
              className="body"
              dangerouslySetInnerHTML={{
                __html: c?.mainContent?.html,
              }}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default MeasurementAttributes
